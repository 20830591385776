import { CheckoutOutputAttributesProps, FrameProps } from 'src/globals/types'

import { CLASSES } from 'src/constants'
import { CHECKOUT_FRAME_CLOSED } from 'src/constants/logs'

import { buildCheckoutUrl, hasValue, hideLoading, isMobile, logger, showLoading } from 'src/utils'
import { each } from 'src/utils/html'
import { attachCheckoutUrl } from 'src/utils/urls'

export const closeCheckoutIFrame = () => {
  logger.log(CHECKOUT_FRAME_CLOSED)

  hideLoading()

  each(CLASSES.PADDLE_FRAME, function (element: HTMLElement) {
    element.parentNode?.removeChild(element)
  })

  if (typeof window.PaddleWindow != 'undefined' && !window.PaddleWindow.closed) {
    window.PaddleWindow.close()
  }
}

export const renderCheckoutFrame = (
  checkoutProps: CheckoutOutputAttributesProps,
  frameProps: FrameProps,
  inline: boolean
) => {
  const checkoutUrl = buildCheckoutUrl(checkoutProps, inline)

  // Show loading spinner while frame is loading.
  if (!inline) {
    showLoading()
  }

  window.PaddleFrame = document.createElement('iframe')
  window.PaddleFrame.className = CLASSES.PADDLE_FRAME
  window.PaddleFrame.name = 'paddle_frame'
  //@ts-ignore ignore deprecation warning
  window.PaddleFrame.frameBorder = '0'
  //@ts-ignore allowTransparency do exist on HTMLIFrameElement
  window.PaddleFrame.allowTransparency = 'true'
  window.PaddleFrame.allow = 'payment'

  if (inline) {
    window.PaddleFrame.classList.add(CLASSES.PADDLE_FRAME_INLINE)
    // We have to inject a <style> tag to the page
    // in order to add a rule that targets the iframe's scrollbar.
    // This is because pseudo-selectors cannot be targeted
    // by inline CSS! This fixes a scrollbar bug in Safari.
    const styleElement = document.createElement('style')
    styleElement.type = 'text/css'
    styleElement.innerHTML = `.${CLASSES.PADDLE_FRAME_INLINE}::-webkit-scrollbar { display: none !important; }`
    document.getElementsByTagName('head')[0].appendChild(styleElement)
  } else {
    window.PaddleFrame.classList.add(CLASSES.PADDLE_FRAME_OVERLAY)
  }
  if (hasValue(frameProps.frameStyle) && inline) {
    window.PaddleFrame.setAttribute('style', `${frameProps?.frameStyle}`)
  } else {
    if (!isMobile()) {
      window.PaddleFrame.setAttribute(
        'style',
        'z-index: 99999; display: block; background-color: transparent; border: 0px none transparent; overflow-x: hidden; overflow-y: auto; visibility: visible; margin: 0px; padding: 0px; -webkit-tap-highlight-color: transparent; position: fixed; left: 0px; top: 0px; width: 100%; height: 100%;'
      )
    } else {
      window.PaddleFrame.setAttribute(
        'style',
        'z-index: 99999; display: block; background-color: transparent; border: 0px none transparent; overflow-x: hidden; overflow-y: scroll; visibility: visible; margin: 0px; padding: 0px; -webkit-tap-highlight-color: transparent; position: fixed; left: 0px; top: 0px; width: 100%; height: 100%;'
      )
    }
  }

  if (hasValue(frameProps.frameInitialHeight) && inline) {
    window.PaddleFrame.setAttribute('height', `${frameProps?.frameInitialHeight}px`)
  }

  attachCheckoutUrl(window.PaddleFrame, checkoutUrl)

  if (hasValue(frameProps.frameTarget) && inline) {
    document.getElementsByClassName(`${frameProps.frameTarget}`)[0].appendChild(window.PaddleFrame as HTMLIFrameElement)
  } else {
    document.getElementsByTagName('body')[0].appendChild(window.PaddleFrame as HTMLIFrameElement)
  }
}
