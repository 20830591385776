import { PaddleEventData } from '@paddle/paddle-js'
import { closeCheckoutIFrame } from 'src/gateway/iframe.gateway'
import { EventData } from 'src/globals/types'

import { Options } from 'src/configs/Options'

import { ACTIONS, CHECKOUT_EVENTS } from 'src/constants/events'

import { hideLoading } from 'src/utils'
import { redirectOnComplete, updateFrameHeight } from 'src/utils/checkout'
import { validateCheckoutOrigin } from 'src/utils/urls'

/**
The original eventData sent by CFE has this structure:
{
  action: '',
  event_name: '',
  callback_data: {}
}

When we fire the event to sellers we want to hide the extra fields and only send the contents of `callback_data`:
{
  name: '',
  data: {}
}
 */
export const _messageListener = function (message: MessageEvent) {
  if (!validateCheckoutOrigin(message.origin)) {
    return
  }
  const { data } = message
  if (data.action === ACTIONS.EVENT || data.action === ACTIONS.CLOSE) {
    fireEvent({
      ...data.callback_data,
    })
    switch (data.event_name?.toLowerCase()) {
      case CHECKOUT_EVENTS.CHECKOUT_CLOSE:
        closeCheckoutIFrame()
        break
      case CHECKOUT_EVENTS.CHECKOUT_REMOVE_SPINNER:
      case CHECKOUT_EVENTS.CHECKOUT_LOADED:
        hideLoading()
        break
      case CHECKOUT_EVENTS.CHECKOUT_PING_SIZE:
        updateFrameHeight(data)
        break
      case CHECKOUT_EVENTS.CHECKOUT_COMPLETE:
        redirectOnComplete()
        break
    }
  }
}

// Listen for messages from the checkout
export function listen(): void {
  window.addEventListener('message', _messageListener, false)
}

export function fireEvent(eventData: EventData) {
  if (typeof Options.eventCallback == 'function') {
    Options.eventCallback(eventData as PaddleEventData)
  }
}
