import { AvailablePaymentMethod, CheckoutOpenOptions, CheckoutSettings, PaddleSetupOptions } from '@paddle/paddle-js'

export enum DISPLAY_MODE {
  OVERLAY = 'overlay', // PaddleJS V2 translates overlay method know to sellers to wide-overlay internally for cfe.
  INLINE = 'inline',
}

export type WIDE_OVERLAY = 'wide-overlay'

export enum ENVIRONMENTS {
  PRODUCTION = 'production',
  STAGING = 'staging',
  SANDBOX = 'sandbox',
  DEVELOPMENT = 'development',
  LOCAL = 'local',
}

export enum THEME {
  LIGHT = 'light',
  DARK = 'dark',
  GREEN = 'green',
}

export enum LOG_LEVEL {
  WARNING = 'warning',
  LOG = 'log',
  ERROR = 'error',
}

export enum RETAIN_DEMO_FEATURE {
  PAYMENT_RECOVERY = 'paymentRecovery',
  PAYMENT_RECOVERY_IN_APP = 'paymentRecoveryInApp',
  TERM_OPTIMIZATION = 'termOptimization',
  TERM_OPTIMIZATION_IN_APP = 'termOptimizationInApp',
  CANCELLATION_FLOW = 'cancellationFlow',
}

export interface EnvironmentSettings {
  checkoutFrontEndBase: string
  checkoutBase: string
  profitwellSnippetBase: string
  apiBase: string
}

export interface EnvProps {
  current: ENVIRONMENTS
  defaults: {
    local: EnvironmentSettings
    development: EnvironmentSettings
    staging: EnvironmentSettings
    sandbox: EnvironmentSettings
    production: EnvironmentSettings
  }
}

export interface EventData {
  name: string
  data: object
  error?: object
}

export interface DataProps {
  libraryVersion: string | null
  completedSetup: boolean
  loadedButtonStylesheet: boolean
}

export type objectType = Record<string, string | number | boolean>

export interface Itemtype {
  priceId: string
  quantity?: number
}

export interface FrameProps {
  frameStyle?: string
  frameInitialHeight?: number
  frameTarget?: string
}

export interface CheckoutOutputAttributesProps extends Omit<InternalCheckoutOpenOptions, 'settings'> {
  settings?: {
    locale?: string
    theme?: THEME
    successUrl?: string
    allowLogout?: boolean
    showAddDiscounts?: boolean
    allowDiscountRemoval?: boolean
    showAddTaxId?: boolean
    frameTarget?: string
    frameInitialHeight?: number
    frameStyle?: string
    displayMode?: DISPLAY_MODE.INLINE | WIDE_OVERLAY
    sourcePage?: string
    referrer?: string | null
    allowedPaymentMethods?: AvailablePaymentMethod[]
  }
  sellerId?: number | null
  clientToken?: string
  applePayEnabled?: boolean
  checkoutInitiated?: number
  'paddlejs-version'?: string | null
}

// To be used only for attributes that are private or while in development. All public attributes should come from `CheckoutSettings`
// We have to override the theme because we support `green`
interface InternalSettings extends Omit<CheckoutSettings, 'theme'> {
  theme?: THEME
}

// We have to override the settings, items and customData objects
export type InternalCheckoutOpenOptions = Omit<CheckoutOpenOptions, 'settings' | 'customData' | 'items'> & {
  settings?: InternalSettings
  customData?: CheckoutOpenOptions['customData'] | string
  items?: CheckoutOpenOptions['items'] | string
  customerAuthToken?: string
  savedPaymentMethodId?: string
}

// We have to override the settings here to support the custom theme
export type InternalPaddleSetupOptions = Omit<PaddleSetupOptions, 'checkout'> & {
  checkout?: {
    settings: InternalSettings
  }
}

export type UpdateCheckoutInputAttributesProps = Omit<CheckoutOpenOptions, 'settings' | 'customData' | 'transactionId'>

export enum RetainCancellationFlowStatus {
  error = 'error',
  aborted = 'aborted',
  choseToCancel = 'chose_to_cancel',
  retained = 'retained',
}
